import React, {
  FC,
  useState,
  useEffect,
  MouseEventHandler,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  CSSObject,
  useTheme,
  Link,
  useScrollTrigger,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  Drawer,
  Divider
} from "@mui/material";
import {
  KeyboardArrowDown,
  HomeWork,
  Groups,
  Sell,
  Support,
  Pets,
  LiveHelp,
  PushPin,
  HomeWorkRounded,
  HelpOutline,
  FlightLand,
  MenuOpen,
  Redeem,
  ArrowForwardIos
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { authService } from "../../services/authService.js";
import LogoImg from "../../assets/images/logo-new.png";
import ResetPasswordCard from "../AuthCard/ResetPassword/ResetPasswordCard";
import LoginIcon from '@mui/icons-material/Person';
import { ArrowBackIos } from "@mui/icons-material";
import { locationLinks, policyLinks } from '../../constants/links';
interface Props {
  sx?: CSSObject;
}

const Navbar: FC<Props> = ({ sx }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElMenu, setAnchorElMenu] = useState<any>(null);
  const [brandsMenuOpen, setBrandsMenuOpen] = useState(false);

  const open = Boolean(anchorElMenu);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleSubMenuToggle = () => {
    setBrandsMenuOpen(!brandsMenuOpen);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [showLogin, setShowLogin] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const trigger = useScrollTrigger();
  const LOGIN = "Login";
  const REGISTER = "Register";
  const FORGOT_PASSWORD = "Forgot";

  const [selectDialog, setSelectDialog] = useState("Login");
  const [isHovered, setIsHovered] = useState(false);
  const [debounce, setDebounce] = useState(null);

  const anchorRef = useRef(null);

  const handleMouseEnter = (e) => {
    if (debounce) return
    if (e.target.classList.contains('MuiBackdrop-root') || e.target.classList.contains('MuiPopover-root') || e.target.classList.contains('MuiPaper-elevation')) {
      setIsHovered(false)
      return
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setDebounce(true)
    const timer = setTimeout(() => {
      setDebounce(false)
    }, 1000)

    setIsHovered(false);
  };

  const handleLogin: MouseEventHandler<Element> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowLogin(true);
  };

  const handleClose = () => {
    setShowLogin(false);
    setSelectDialog(LOGIN);
  };

  const handleProfileClick = () => {
    setIsHovered(false);
    const token = authService.getUser();
    console.log(token);
    if (token) {
      navigate("/profile");
      return;
    }
    setShowLogin(true);
  };

  const path = location.pathname;

  const linkStyle = {
    padding: ".25rem 1rem",
    borderRadius: "6px",
    mr: ".75rem",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    "&:hover": { color: "#03989E" },
    color: "black",
  };

  return (
    <>
      <AppBar
        position={path === "/" ? "fixed" : "relative"}
        style={{
          background: "white",
          WebkitBackdropFilter: "blur(6px)",
          backdropFilter: "blur(6px)",
          backgroundColor: "white",
        }}
        sx={{ boxShadow: trigger ? 2 : 0, borderBottom: "1px solid #ddd" }}
      >
        <Toolbar sx={{ display: "block", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: { xs: "56px", sm: "84px" },
              padding: { xs: 0 , sm: 0, md:  '0 8%' }
            }}
          >
            <Hidden lgDown>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                </Box>
              </Box>
              <Box
                component="img"
                src={LogoImg}
                alt="Logo"
                draggable="false"
                onClick={() => navigate("/")}
                sx={{
                  maxWidth: "140px",
                  mr: "auto",
                  cursor: "pointer",
                  objectFit: 'contain',
                  height: "60px",
                }}
              />
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickMenu}
                sx={{ borderRadius: '12px', border: '1px solid #d3d3d3' }}
              >
                <LoginIcon /> <KeyboardArrowDown />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElMenu}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Box
                  sx={{
                    width: 250,
                    padding: 2,
                  }}
                >
                  {!authService.getUser() && (
                    <div ref={anchorRef}>
                      <Link
                        href="/login"
                        style={{
                          borderRadius: "6px",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #03989E",
                          color: "#03989E",
                          padding: "3px 10px",
                          cursor: "pointer",
                          marginLeft: "1rem",
                          marginRight: '1rem'
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#03989E";
                          e.currentTarget.style.color = "#FFFFFF";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.color = "#03989E";
                        }}
                      >
                        <LoginIcon style={{ marginRight: "0.1em", fontSize: "1.5em" }} />
                        <Box
                          component="span"
                          sx={{
                            fontSize: "1em",
                            fontWeight: 500,
                            lineHeight: "28px",
                          }}
                        >
                          Sign in / Register
                        </Box>
                      </Link>
                      <div style={{ marginTop: '0.5rem', marginBottom: '0.25rem' }}>
                        <Divider />
                      </div>
                    </div>
                  )}
                  {authService.getUser() && (
                    <Link
                      sx={{
                        borderRadius: "6px",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": { color: "#03989E" },
                        color: "black",
                        cursor: "pointer",
                        ...linkStyle,
                      }}
                      href="/profile"
                    >
                      <div
                        style={{
                          fontSize: "1em",
                          lineHeight: "28px",
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem'
                        }}
                      >
                        <MenuOpen /> Dashboard
                      </div>
                    </Link>
                  )}
                  <Link
                    href="/romingo-rewards"
                    sx={{
                      mr: "1rem",
                      ...linkStyle,
                    }}
                  >
                    <div style={{ fontSize: "1em", lineHeight: "28px", display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', textWrap: 'nowrap'}}><Redeem /> Romingo Rewards</span>
                      <span style={{ padding: '0.1rem 0.75rem', backgroundColor: '#D21404', color: 'white', borderRadius: '6px', fontSize: '13px' }}>New!</span>
                    </div>
                  </Link>
                  <Link
                    href="/reservation/manage"
                    sx={{
                      mr: "1rem",
                      ...linkStyle,
                    }}
                  >
                    <div style={{ fontSize: "1em", lineHeight: "28px", display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FlightLand /> My trips
                    </div>
                  </Link>
                  <Link href="/faq" sx={{
                    mr: "1rem",
                    ...linkStyle,
                  }}>
                    <div
                      style={{
                        marginLeft: "",
                        fontSize: "1em",
                        lineHeight: "28px",
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}
                    >
                      <HelpOutline /> FAQ
                    </div>
                  </Link>
                </Box>
              </Menu>
            </Hidden>

            <Hidden lgUp>

              {/* TODO: Finish menu */}
              <MenuComponent />

              <Box
                component="img"
                src={LogoImg}
                alt="Logo"
                onClick={() => {
                  navigate("/");
                }}
                draggable="false"
                sx={{
                  mr: "auto",
                  ml: "auto",
                  maxHeight: { xs: "24px", md: "24px" },
                }}
              />

              {/* <DropdownMenuNav /> */}
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickMenu}
                sx={{ borderRadius: '12px', border: '1px solid #d3d3d3' }}
              >
                <LoginIcon /> <KeyboardArrowDown />
              </Button>
              <Drawer
                anchor="right"
                open={open}
                onClose={handleCloseMenu}
              >
                <Box
                  sx={{
                    width: 270,
                    px: 1,
                    py: 3,
                    gap: '0.5rem',
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {!authService.getUser() && (
                    <div ref={anchorRef}>
                      <Link
                        href="/login"
                        style={{
                          borderRadius: "6px",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #03989E",
                          color: "#03989E",
                          padding: "3px 10px",
                          cursor: "pointer",
                          marginLeft: "1rem",
                          marginRight: '1rem'
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#03989E";
                          e.currentTarget.style.color = "#FFFFFF";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.color = "#03989E";
                        }}
                      >
                        <LoginIcon style={{ marginRight: "0.1em", fontSize: "1.5em" }} />
                        <Box
                          component="span"
                          sx={{
                            fontSize: "1em",
                            fontWeight: 500,
                            lineHeight: "28px",
                          }}
                        >
                          Sign in / Register
                        </Box>
                      </Link>
                      <div style={{ paddingLeft: '1rem', paddingRight: '1rem', marginTop: '1rem', marginBottom: '0.25rem' }}>

                      </div>
                    </div>
                  )}
                  {authService.getUser() && (
                    <Link
                      sx={{
                        borderRadius: "6px",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": { color: "#03989E" },
                        color: "black",
                        cursor: "pointer",
                        ...linkStyle,
                      }}
                      href="/profile"
                    >
                      <div
                        style={{
                          fontSize: "1em",
                          lineHeight: "28px",
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem'
                        }}
                      >
                        <MenuOpen /> Dashboard
                      </div>
                    </Link>
                  )}
                  <Link
                    href="/romingo-rewards"
                    sx={{
                      mr: "1rem",
                      ...linkStyle,
                    }}
                  >
                    <div style={{ fontSize: "1em", lineHeight: "28px", display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', textWrap: 'nowrap'}}><Redeem /> Romingo Rewards</span>
                      <span style={{ padding: '0.1rem 0.75rem', backgroundColor: '#D21404', color: 'white', borderRadius: '6px', fontSize: '13px' }}>New!</span>
                    </div>
                  </Link>
                  <Link
                    href="/reservation/manage"
                    sx={{
                      mr: "1rem",
                      ...linkStyle,
                    }}
                  >
                    <div style={{ fontSize: "1em", lineHeight: "28px", display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FlightLand /> My trips
                    </div>
                  </Link>

                  <div style={{ paddingLeft: '1rem', paddingRight: '1rem', marginTop: '1rem', marginBottom: '0rem' }}>
                    <Divider />
                  </div>
                  <MenuItem
                    onClick={() => navigate("/about")}
                    component="button"
                    sx={{ fontWeight: 500, minWidth: "200px", mt: '0.5rem' }}
                  >
                    <Pets
                      sx={{ mr: "1.5rem", fontSize: "16px", color: "#666" }}
                    />{" "}
                    Our story
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/romingo-partners")}
                    component="button"
                    sx={{ fontWeight: 500, minWidth: "200px" }}
                  >
                    <Groups
                      sx={{ mr: "1.5rem", fontSize: "16px", color: "#666" }}
                    />{" "}
                    Philanthropy
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/faq")}
                    component="button"
                    sx={{ fontWeight: 500, minWidth: "200px" }}
                  >
                    <LiveHelp
                      sx={{ mr: "1.5rem", fontSize: "16px", color: "#666" }}
                    />{" "}
                    FAQ
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/blog")}
                    component="button"
                    sx={{ fontWeight: 500, minWidth: "200px" }}
                  >
                    <PushPin
                      sx={{ mr: "1.5rem", fontSize: "16px", color: "#666" }}
                    />{" "}
                    Blog
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/contact")}
                    component="button"
                    sx={{ fontWeight: 500, minWidth: "200px" }}
                  >
                    <Support
                      sx={{ mr: "1.5rem", fontSize: "16px", color: "#666" }}
                    />{" "}
                    Support
                  </MenuItem>
                  <RomingoDealsMobile />
                  <MenuItem
                    component="button"
                    onClick={() => navigate("/blog")}
                    sx={{ fontWeight: 500, minWidth: "200px" }}
                  >
                    <HomeWork
                      sx={{ mr: "1.5rem", fontSize: "16px", color: "#666" }}
                    />{" "}
                    Travel tips
                  </MenuItem>
                </Box>
              </Drawer>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

const MenuComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenu, setSubMenu] = useState(null);

  const mainMenu = [
    { name: "Amazing Brands", key: "brands", icon: <HomeWorkRounded /> },
    { name: "Best Destinations", key: "destinations", icon: <FlightLand /> },
  ];

  const brandsMenu = policyLinks.map((item) => ({
    name: item.name.replace("pet policy", ""),
    to: `/${item.to}`,
  }));

  const destinationsMenu = locationLinks.map((item) => ({
    name: item.name,
    to: `/${item.to}`,
  }));

  const getMenuItems = () => {
    if (subMenu === "brands") return brandsMenu;
    if (subMenu === "destinations") return destinationsMenu;
    return mainMenu;
  };

  const getMenuText = (key) => {
    return mainMenu.find(item => item.key === key)?.name;
  }

  return (
    <>
      <Link onClick={() => setMenuOpen(true)}
        sx={{
          padding: "0rem 0rem",
          minWidth: "55px",
          background: "transparent",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          textDecoration: "none",
          "&:hover": { color: "#999" },
          cursor: "pointer",
          color: menuOpen ? "#999" : "#222",
          fontFamily: "Lato",
          fontWeight: 500,
          fontSize: "14px",
        }}>
        <MenuIcon />
      </Link>
      <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)} sx={{ zIndex: 9999, position: 'relative' }}>
        {/* close button */}
        <IconButton onClick={() => setMenuOpen(false)} sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ minWidth: '90vw', padding: '20px', mt: '2rem' }}>
          {subMenu && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, pb: 1, borderBottom: '1px solid rgba(19, 18, 18, 0.1)' }}>
              <IconButton onClick={() => setSubMenu(null)}>
                <ArrowBackIos sx={{ fontSize: "18px" }} />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'right' }}>
                {getMenuText(subMenu)}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: "flex", flexDirection: "column", gap: '0.5rem', mt: '1rem' }}>
            {getMenuItems().map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => item.key ? setSubMenu(item.key) : window.location.href = item.to}
                sx={{ fontWeight: 500, border: '1px solid rgba(19, 18, 18, 0.2)', borderRadius: '5px' }}
              >
                {item.key && <Box sx={{ mr: '1rem' }}>{item.icon}</Box>}
                {item.name}
                {item.key && <ArrowForwardIos sx={{ fontSize: "16px", marginLeft: "auto" }} />}
              </MenuItem>
            ))}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const RomingoDealsMobile = () => {
  const [showDealsPopup, setShowDealsPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  useEffect(() => {
    setEmailIsValid(/^\S+@\S+\.\S+$/.test(email));
  }, [email]);

  useEffect(() => {
    emailSubmitted && setIsSubmitting(false);
    !emailSubmitted && setEmail("");
  }, [emailSubmitted]);

  useEffect(() => {
    if (!showDealsPopup)
      setTimeout(() => {
        setEmailSubmitted(false);
      }, 250);
  }, [showDealsPopup]);

  const submitEmail = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      fetch(
        `https://romingo.us6.list-manage.com/subscribe/post-json?u=585083137c3540a7371e3a74f&id=d4d3932414&EMAIL=${encodeURIComponent(
          email
        )}&c=?`,
        { mode: "no-cors", method: "POST" }
      ).then((res) => {
        setEmailSubmitted(true);
      });
    }, 500);
  };

  return (
    <>
      <span
        style={{
          cursor: "pointer",
          transition: "all .25s ease-in-out",
          overflow: "hidden",
          maxHeight: showDealsPopup ? "200px" : "50px",
        }}
      >
        <MenuItem
          onClick={(e) => {
            setShowDealsPopup(!showDealsPopup);
          }}
          sx={{ fontWeight: 500, minWidth: "200px" }}
        >
          <Sell sx={{ mr: "1.5rem", fontSize: "16px", color: "#666" }} />
          Deals
          <KeyboardArrowDown
            sx={{
              opacity: showDealsPopup ? 1 : 0,
              ml: "auto",
              transition: "all .25s ease-in-out",
              transform: showDealsPopup ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </MenuItem>
        {showDealsPopup ? (
          <Grid
            container
            sx={{
              maxWidth: "280px",
              minHeight: "100px",
              display: "flex",
              overflow: "hidden",
              padding: "0rem .5rem",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#00000099",
                p: ".5rem .5rem",
                pb: ".125rem",
                fontFamily: "Lato",
                fontWeight: 900,
                fontSize: ".9rem",
              }}
            >
              Sign up with your email below to access exclusive deals and more:
            </Grid>
            {emailSubmitted ? (
              <Grid
                item
                xs={12}
                sx={{
                  p: ".5rem .5rem .5rem .5rem",
                  fontFamily: "Lato",
                  color: "#03989E",
                  textAlign: "center",
                }}
              >
                Awesome! You&lsquo;re subscribed to deals, tips, guides, and all
                the other great content from Romingo Insiders!
              </Grid>
            ) : (
              <>
                {isSubmitting && (
                  <Grid
                    sx={{
                      position: "absolute",
                      zIndex: 9,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: "0px",
                      right: "0",
                      left: "0",
                      bottom: "0px",
                      background: "#ffffff80",
                      backdropFilter: "blur(1px)",
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    p: ".25rem .5rem .5rem .5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    variant="outlined"
                    color="primary"
                    placeholder="enter email"
                    size="small"
                    value={email}
                    sx={{
                      outline: "none",
                      fontSize: ".8rem",
                      input: { fontFamily: "Lato" },
                      "& label.Mui-focused": {
                        color: "#03989E",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "green",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#03989E",
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: ".5rem",
                        maxHeight: "30px",
                        fontFamily: "Lato",
                        outline: "none",
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    onClick={submitEmail}
                    disabled={!emailIsValid || isSubmitting}
                    size="small"
                    fullWidth
                    variant="contained"
                    sx={{
                      fontWeight: 600,
                      textTransform: "none",
                      fontFamily: "Lato",
                      mt: ".5rem",
                    }}
                  >
                    Sign up!
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </span>
    </>
  );
};

export default Navbar;
