import { FC, useState, useEffect } from "react";
import Navbar from "../Navbar";
import {
  Box,
  CSSObject,
  useMediaQuery
} from "@mui/material";

import MobileFilterBar from '../MobileHomePageFilterBar/MobileHomePageFilterBar'
import { LargeFilterBar } from '../LargeFilterBar'

import "./Header.scss";

import hero1 from '../../assets/public/hero/desktop1.jpg'
// import hero2 from '../../assets/public/hero/desktop2.jpg'
import hero3 from '../../assets/public/hero/desktop3.jpg'

// import hero4 from '../../assets/public/hero/mobile1.jpg'
import hero5 from '../../assets/public/hero/mobile2.jpg'
// import hero6 from '../../assets/public/hero/mobile3.jpg'

interface Props {
  sx?: CSSObject;
}

const Header: FC<Props> = ({ sx }) => {

  const imagesDesktop = [
    {
      component: hero1,
      placement: 'bottom 0px right 0px'
    },
    // {
    //   component: hero2,
    //   placement: 'top 40px left 0px'
    // },
    {
      component: hero3,
      placement: 'top 0px right 0px'
    },
    // {
    //   component: hero2,
    //   placement: 'bottom -40px right 0px'
    // },
    // {
    //   component: hero3,
    //   placement: 'bottom -40px right 0px'
    // },
    // {
    //   component: hero4,
    //   placement: 'bottom -40px right 0px'
    // },
    // {
    //   component: hero5,
    //   placement: 'bottom -40px right 0px'
    // },
    // {
    //   component: hero6,
    //   placement: 'bottom -40px right 0px'
    // }
  ];
  const imagesMobile = [
    // {
    //   component: hero1, 
    //   placement: 'bottom -50px left -120px',
    // },
    // {
    //   component: hero2, 
    //   placement: 'bottom -50px left 50%'
    // },
    {
      component: hero3,
      placement: 'bottom -50px left 50%'
    },
    // {
    //   component: hero4,
    //   placement: 'bottom -20px left 50%'
    // },
    {
      component: hero5,
      placement: 'bottom 0px left 50%'
    },
    // {
    //   component: hero6,
    //   placement: 'bottom -20px left 50%'
    // }
  ];

  const [mobileImage, setMobileImage] = useState({})
  const [desktopImage, setDesktopImage] = useState({})

  const isMobile = useMediaQuery("(max-width:800px)");


  useEffect(() => {
    setMobileImage(imagesMobile[Math.floor(Math.random() * imagesMobile.length)])
    setDesktopImage(imagesDesktop[Math.floor(Math.random() * imagesDesktop.length)])
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: { xs: "0px" },
        height: { xs: "560px", sm: "100%" },
        display: "flex",
        justifyContent: "start",
        flexDirection: 'column',
        top: 0,
        left: 0,
        ...sx,
      }}
      className="header-wrapper"
    >
      <Navbar />
      <Box
        className="filter-bar-wrapper"
        sx={{
          backgroundImage: { xs: `${mobileImage.extra ? mobileImage.extra : ''} url(${mobileImage.component})`, sm: `url(${mobileImage.component})`, md: `url(${desktopImage.component})` },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { xs: mobileImage.placement, sm: mobileImage.placement, md: desktopImage.placement },
          backgroundSize: 'cover',
        }}
      >

        <Box sx={{
          ml: { xs: '1em', sm: '1.5em', md: '1.5em', lg: '0em' },
          mt: { xs: '4.5em', sm: '8em', md: '9em', lg: '9em' },
          mb: { xs: '0em', sm: '0em', md: '0em', lg: '2em' },
        }}>

          <Box sx={{
            display: { xs: 'block', sm: 'block', md: 'none' }
          }}>
            <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }} className="filter-bar-wrapper-title">
              <h1 style={{ fontSize: 'inherit'}}><b>Welcome to Romingo</b></h1>
            </Box>
            <Box
              sx={{
                fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' },  
                textAlign: { xs: 'center', sm: 'left' },
              }}
              className="filter-bar-wrapper-desc"
            >
              Explore pet friendly hotels & rentals
            </Box>
          </Box>
        </Box>

        {isMobile &&
          <Box sx={{
            display: { xs: 'block', md: 'none' },
            top: { xs: '00px' },
          }}>
            <MobileFilterBar />
          </Box>
        }
        {!isMobile &&
          <Box sx={{
            display: { xs: 'none', md: 'block' }
          }}>
            <LargeFilterBar showText={true} />
          </Box>
        }
      </Box>
    </Box >
  );
};


export default Header;
