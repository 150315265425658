import { FC, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ClearIcon from "@mui/icons-material/Clear";

import NumberInput from "./NumberInput";
import FullPageDialog from "../FullPageDialog";

export interface Occupant {
  adults: number;
  children: number;
  dogs: number;
  childrenAge?: number[];
  disabled?: boolean;
}

interface OccupantSelectorProps {
  value: Occupant;
  onChange: (value: Occupant) => void;
  onClose?: () => void;
  fullWidth?: boolean;
  size?: "small" | "medium" | undefined;
  variant?: "filled" | "outlined" | "standard" | undefined;
  disabled?: boolean;
  align?: "left" | "right";
}

const OccupantSelector: FC<OccupantSelectorProps> = ({
  value,
  onChange,
  onClose,
}) => {
  const [showOccupantDialog, setShowOccupantDialog] = useState(false);
  const [error, setError] = useState("");

  const handleClick = () => {
    setShowOccupantDialog(true);
  };

  const handleClose = () => {
    setError("");
    if (value.adults === 0) {
      setError("At least 1 adult is required");
      return;
    }
    if (onClose) {
      onClose();
    }
    setShowOccupantDialog(false);
  };

  return (
    <>
      <Grid
        container
        onClick={handleClick}
        sx={{
          border: "1px solid #aaabab",
          borderRadius: "6px",
          padding: ".5rem 0rem .5rem 0.75rem",
          backgroundColor: 'white',
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            ml: '0.5em',
            mr: '0.25em',
          }}
        >
          <PeopleIcon 
            sx={{
              fontSize: "1.5em",
              pointerEvents: "auto",
              mr: '0.25em',
              height: '24px',
              p: '0.1em',
              pl: 0,
            }} 
          />
        </Grid>
        <Typography 
          variant="body1"
          sx={{
            mt: '0.25em',
            ml: '0.25em',
          }}
        >
          {value.adults + value.children} Guests, {value.dogs} Pet
          {value.dogs === 1 ? "" : "s"}
        </Typography>
      </Grid>

      <FullPageDialog isOpen={showOccupantDialog} close={handleClose}>
        <Box
          sx={{
            zIndex: 10000,
            width: "100%",
            height: "100%",
            position: "fixed",
            backgroundColor: "white",
            left: 0,
            top: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* HEADER */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              pb: "5px",
              pt: "40px",
            }}
          >
            {/* Close Button */}
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", right: 10, top: 35 }}
            >
              <ClearIcon />
            </IconButton>

            {/* Title */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                color: "#009688",
              }}
            >
              Guests and Pets
            </Typography>
          </Box>

          {/* OCCUPANT FIELDS */}
          <Box sx={{ p: 3, flex: 1, overflowY: "auto" }}>
            <Stack spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Adults</Typography>
                <NumberInput
                  value={value.adults}
                  onChange={(adults) => {
                    if (adults > 5) return;
                    onChange({ ...value, adults });
                  }}
                  minimum={1}
                  maximum={5}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Children</Typography>
                <NumberInput
                  value={value.children}
                  onChange={(children) => {
                    if (children > 6) return;
                    if (value.childrenAge && value.childrenAge.length > children) {
                      value.childrenAge = value.childrenAge.slice(0, children);
                    } else if (
                      value.childrenAge &&
                      value.childrenAge.length <= children
                    ) {
                      while (value.childrenAge.length !== children) {
                        value.childrenAge.push(0);
                      }
                    }
                    onChange({ ...value, children });
                  }}
                  maximum={6}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Dogs</Typography>
                <NumberInput
                  value={value.dogs}
                  onChange={(dogs) => {
                    if (dogs > 2) return;
                    onChange({ ...value, dogs });
                  }}
                  maximum={2}
                />
              </Stack>
              {error.length > 0 && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ textAlign: "center", fontSize: "80%" }}
                >
                  {error}
                </Typography>
              )}
              {value.children > 0 && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <Box alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
                    {Array.from({ length: value.children }, (_, i: number) => (
                      <Stack
                        key={i}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Typography>Child {i + 1} Age</Typography>
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <Select
                            key={i}
                            color="primary"
                            variant="outlined"
                            size="small"
                            sx={{ textAlign: "center" }}
                            value={
                              value.childrenAge && value.childrenAge[i]
                                ? value.childrenAge[i].toString()
                                : "1"
                            }
                            onChange={(e: any) => {
                              if (value.childrenAge === undefined) {
                                value.childrenAge = [];
                              }
                              value.childrenAge[i] = parseInt(e.target.value);
                              onChange({ ...value });
                            }}
                          >
                            {Array.from({ length: 17 }, (_, k: number) => (
                              <MenuItem value={k + 1} key={k + 1}  sx={{ justifyContent: "center" }}>
                                {k + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    ))}
                  </Box>
                </>
              )}
            </Stack>
          </Box>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              borderRadius: "8px",
              fontSize: "1rem",
              width: "auto",
              textTransform: "none",
              m: 3,
            }}
          >
            Done
          </Button>
        </Box>
      </FullPageDialog>
    </>
  );
};

export default OccupantSelector;