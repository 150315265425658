import { FC, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NearMeIcon from "@mui/icons-material/NearMe";

import GooglePlaceAutoComplete from "../GooglePlaceAutoComplete";
import FullPageDialog from "./FullPageDialog";
import { useRecentSearches } from "../../hooks/useRecentSearches";
import { useNearbyLocations } from "../../hooks/useNearbyLocations";
interface LocationSelectorProps {
  mobileText: string;
  setMobileText: (arg0: string) => void;
  setShowCities: (arg0: boolean) => void;
  handlePredictions: any;
  handleCityClick: any;
  selectedCity: any;
  setSelectedCity: any;
  setNewValue: any;
  newValue: any;
  mobileTextSearchRef: any;
  showCities: boolean;
  predictions: Array<any>;
  mapOnPage?: boolean;
  setPredictions?: (arr: Array<any>) => void;
}

const LocationSelector: FC<LocationSelectorProps> = (props) => {
 
  const {
    mobileText = "",
    predictions = [],
    setMobileText,
    setShowCities,
    handlePredictions,
    selectedCity,
    setSelectedCity,
    setNewValue,
    newValue,
    mobileTextSearchRef,
    handleCityClick,
    showCities,
    setPredictions,
    mapOnPage = false,
  } = props;

 
  const safeMobileText = mobileText || "";
  const safePredictions = predictions || [];

  const { recentSearches, addRecentSearch, clearRecentSearches } = useRecentSearches();
  const { coords, error } = useNearbyLocations();

 
  const clearSearchBar = (inputRef: any) => {
    setMobileText("");
    setPredictions?.([]);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCitySelect = (value: string, e: any) => {
    setMobileText(value);

  };

 
  const handleNearbyClick = () => {

    if (!navigator.permissions) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new window.google.maps.Geocoder();
          const latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results && results[0]) {
              setMobileText(results[0].formatted_address);
              addRecentSearch({ address: results[0].formatted_address });
              setShowCities(false);
            } else {
              console.error("Geocoder failed or no results:", status);
            }
          });
        },
        (err) => {
          console.error("Geolocation error:", err);
        }
      );
      return;
    }
    navigator.permissions.query({ name: "geolocation" }).then((permissionStatus) => {
 
      const geocodeCoords = (lat: number, lng: number) => {
        if (typeof window.google === "undefined" || !window.google.maps) {
          alert("Google Maps API is not loaded. Please check your API script tag.");
          return;
        }
        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results && results[0]) {
            setMobileText(results[0].formatted_address);
            addRecentSearch({ address: results[0].formatted_address });
            setShowCities(false);
          } else {
            console.error("Geocoder failed or no results:", status);
          }
        });
      };

      if (permissionStatus.state === "granted") {
        // If already granted, request location immediately.
        navigator.geolocation.getCurrentPosition(
          (position) => {
            geocodeCoords(position.coords.latitude, position.coords.longitude);
          },
          (err) => {
            console.error("Geolocation error:", err);
          }
        );
      } else if (permissionStatus.state === "prompt") {
 
        navigator.geolocation.getCurrentPosition(
          (position) => {
            geocodeCoords(position.coords.latitude, position.coords.longitude);
          },
          (err) => {
            console.error("Geolocation error:", err);
          }
        );
  
        permissionStatus.onchange = () => {
          if (permissionStatus.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                geocodeCoords(position.coords.latitude, position.coords.longitude);
              },
              (err) => {
                console.error("Geolocation error:", err);
              }
            );
          }
        };
      } else {
    
        alert("Location access has been denied. Please enable location services in your browser settings.");
      }
    });
  };

  return (
    <>
      {/* MAIN SEARCH (always visible) */}
      <Grid
        container
        sx={{
          border: "1px solid #aaabab",
          borderRadius: "6px",
          padding: "0 0 0 20px",
          backgroundColor: "white",
        }}
        alignItems="center"
      >
        <Grid item>
          <LocationOnIcon />
        </Grid>
        <Grid item xs={8} sx={{ zIndex: 50, minWidth: "90%" }}>
          <GooglePlaceAutoComplete
            mobile={true}
            mobileText={safeMobileText}
            setMobileText={setMobileText}
            setShowCities={setShowCities}
            callback={handlePredictions}
            city={selectedCity}
            setSelectedCity={setSelectedCity}
            mapOnPage={mapOnPage}
          />
        </Grid>
      </Grid>

 
      <FullPageDialog isOpen={showCities} close={() => setShowCities(false)}>
        <Box
          sx={{
            zIndex: 10000,
            width: "100%",
            height: "100%",
            position: "fixed",
            backgroundColor: "white",
            left: 0,
            top: 0,
          }}
        >
          {/* HEADER */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            pb: "5px",
              pt: "40px",  
   
            }}
          >
            {/* Close Button */}
            <IconButton
              onClick={() => setShowCities(false)}
              sx={{ position: "absolute", right: 10, top: 35 }}
            >
              <ClearIcon />
            </IconButton>

            {/* Title */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                color: "#009688",
              }}
            >
              Place
            </Typography>
          </Box>

          {/* SEARCH FIELD */}
          <Box sx={{ p: 2 }}>
            <TextField
              autoFocus
              id="mobileTextSearch"
              variant="outlined"
              placeholder="Search for a place"
              fullWidth
              size="small"
              value={safeMobileText}
              inputRef={mobileTextSearchRef}
              onChange={(e) => {
             
                console.log(e);
                console.log(e.target.value);
                  handleCitySelect(e.target.value, e);
            
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                  opacity: safeMobileText.length > 0 ? 1 : 0.7,
                },
              }}
              InputProps={{
                endAdornment: safeMobileText.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear"
                      sx={{ cursor: "pointer" }}
                      onClick={() => clearSearchBar(mobileTextSearchRef)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {safeMobileText.length === 0 && (
            <>
              {/* NEARBY */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  px: 2,
                  mt: 1,
                  ml: 1,
                  mb: 1,
                  cursor: "pointer",
                }}
                onClick={handleNearbyClick}
              >
                <NearMeIcon sx={{ color: "#009688" }} />
                <Typography
                  sx={{
                    color: "#009688",
                    fontWeight: 500,
                    fontSize: "1rem",
                  }}
                >
                  Nearby
                </Typography>
              </Box>

              {/* RECENT SEARCHES */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 2,
                  mb: 1,
                  mt: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Recent Searches
                </Typography>
                {recentSearches.length > 0 && (
                  <Typography
                    variant="body2"
                    sx={{ color: "#009688", cursor: "pointer" }}
                    onClick={clearRecentSearches}
                  >
                    Clear
                  </Typography>
                )}
              </Box>

              {/* LIST OF RECENT SEARCHES */}
              {recentSearches.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 2,
                    py: 1,
                    borderTop: "1px solid #f3f3f3",
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f7f7f7" },
                  }}
                  onClick={() => {
                    setMobileText(item.address);
                    setShowCities(false);
                  }}
                >
                  <LocationOnIcon sx={{ mr: 1,ml:1, color: "#009688" }} />
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {item.address}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {item.country}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          )}

          {/* PREDICTIONS SECTION */}
          <Box
            sx={{
              p: 2,
              mt: 1,
              height: "calc(100% - 300px)",
              overflowY: "auto",
            }}
          >
            {safePredictions?.map((address, index) => (
              <Box
                sx={{
                  px: 1,
                  py: 1,
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#d9f7fc" },
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #f0f0f0",
                }}
                key={address.description}
                onClick={(e) =>{ 
                    handleCityClick(e, safePredictions[index])
                    addRecentSearch({ address: safePredictions[index].description });
                }}
              >
                <LocationOnIcon sx={{ color: "#009688", mr: 1 }} />
                <Typography variant="body1">{address.description}</Typography>
              </Box>
            ))}

            {/* No matches */}
            {safePredictions?.length === 0 && safeMobileText?.length > 0 && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                No matching results.
              </Typography>
            )}
          </Box>
        </Box>
      </FullPageDialog>
    </>
  );
};

export default LocationSelector;
