export const locationLinks = [
    { to: "pet-friendly-hotels/los-angeles-california", name: 'Los Angeles' },
    { to: "pet-friendly-hotels/san-francisco-california", name: 'San Francisco' },
    { to: "pet-friendly-hotels/san-diego-california", name: 'San Diego' },
    { to: "pet-friendly-hotels/palm-springs-california", name: 'Palm Springs' },
    { to: "pet-friendly-hotels/austin-texas", name: 'Austin' },
    { to: "pet-friendly-hotels/dallas-texas", name: 'Dallas' },
    { to: "pet-friendly-hotels/houston-texas", name: 'Houston' },
    { to: "pet-friendly-hotels/phoenix-arizona", name: 'Phoenix' },
    { to: "pet-friendly-hotels/scottsdale-arizona", name: 'Scottsdale' },
    { to: "pet-friendly-hotels/tucson-arizona", name: 'Tucson' },
    { to: "pet-friendly-hotels/santa-fe-new-mexico", name: 'Santa Fe' },
    { to: "pet-friendly-hotels/san-antonio-texas", name: 'San Antonio' },
    { to: "pet-friendly-hotels/denver-colorado", name: 'Denver' },
    { to: "pet-friendly-hotels/seattle-washington", name: 'Seattle' },
    { to: "pet-friendly-hotels/portland-oregon", name: 'Portland' },
    { to: "pet-friendly-hotels/salt-lake-city-utah", name: 'Salt Lake City' },
    { to: "pet-friendly-hotels/atlanta-georgia", name: 'Atlanta' },
    { to: "pet-friendly-hotels/baltimore-maryland", name: 'Baltimore' },
    { to: "pet-friendly-hotels/boston-massachusetts", name: 'Boston' },
    { to: "pet-friendly-hotels/charlotte-north-carolina", name: 'Charlotte' },
    { to: "pet-friendly-hotels/chicago-illinois", name: 'Chicago' },
    { to: "pet-friendly-hotels/cleveland-ohio", name: 'Cleveland' },
    { to: "pet-friendly-hotels/detroit-michigan", name: 'Detroit' },
    { to: "pet-friendly-hotels/indianapolis-indiana", name: 'Indianapolis' },
    { to: "pet-friendly-hotels/miami-florida", name: 'Miami' },
    { to: "pet-friendly-hotels/milwaukee-wisconsin", name: 'Milwaukee' },
    { to: "pet-friendly-hotels/minneapolis-minnesota", name: 'Minneapolis' },
    { to: "pet-friendly-hotels/new-orleans-louisiana", name: 'New Orleans' },
    { to: "pet-friendly-hotels/new-york-new-york", name: 'New York City' },
    { to: "pet-friendly-hotels/washington-dc", name: 'Washington D.C.' },
    { to: "pet-friendly-hotels/las-vegas-nevada", name: 'Las Vegas' },
    { to: "pet-friendly-hotels/asheville-north-carolina", name: "Asheville" },
    { to: "pet-friendly-hotels/orlando-florida", name: "Orlando" },
    { to: "pet-friendly-hotels/nashville-tennessee", name: "Nashville" },
    { to: "pet-friendly-hotels/key-west-florida", name: "Key West" },
    { to: "pet-friendly-hotels/st-george-utah", name: "St. George" },
    { to: "pet-friendly-hotels/charleston-south-carolina", name: "Charleston" },
    { to: "pet-friendly-hotels/jacksonville-florida", name: "Jacksonville" },
    { to: "pet-friendly-hotels/colorado-springs-colorado", name: "Colorado Springs" },
    { to: "pet-friendly-hotels/flagstaff-arizona", name: "Flagstaff" },
    { to: "pet-friendly-hotels/sedona-arizona", name: "Sedona" },
    { to: "pet-friendly-hotels/lake-tahoe-california", name: "Lake Tahoe" },
    { to: "pet-friendly-hotels/santa-cruz-california", name: "Santa Cruz" },
    { to: "pet-friendly-hotels/monterey-california", name: "Monterey" },
    { to: "pet-friendly-hotels/santa-barbara-california", name: "Santa Barbara" },
    { to: "pet-friendly-hotels/naples-florida", name: "Naples" },
    { to: "pet-friendly-hotels/reno-nevada", name: "Reno" },
    { to: "pet-friendly-hotels/breckenridge-colorado", name: "Breckenridge" },
    { to: "pet-friendly-hotels/aspen-colorado", name: "Aspen" },
    { to: "pet-friendly-hotels/san-luis-obispo-california", name: "San Luis Obispo" },
    { to: "pet-friendly-hotels/sacramento-california", name: "Sacramento" },
    { to: "pet-friendly-hotels/philadelphia-pennsylvania", name: "Philadelphia" },
    { to: "pet-friendly-hotels/santa-monica-california", name: "Santa Monica" },
    { to: "pet-friendly-hotels/myrtle-beach-south-carolina", name: "Myrtle Beach" },
    { to: "pet-friendly-hotels/st-louis-missouri", name: "St. Louis" },

];

export const policyLinks = [
    { to: 'marriott-pet-policy', name: "Marriott hotels pet policy" },
    { to: 'hilton-pet-policy', name: "Hilton hotels pet policy" },
    { to: 'hyatt-pet-policy', name: "Hyatt hotels pet policy" },
    { to: "motel-6-pet-policy", name: "Motel 6 hotels pet policy" },
    { to: 'la-quinta-pet-policy', name: "La Quinta Inn pet policy" },
    { to: 'holiday-inn-pet-policy', name: "Holiday Inn pet policy" },
    { to: 'doubletree-pet-policy', name: "DoubleTree pet policy" },
    { to: 'embassy-suites-pet-policy', name: "Embassy Suites pet policy" },
    { to: 'best-western-pet-policy', name: "Best Western pet policy" },
    { to: 'sheraton-pet-policy', name: "Sheraton pet policy" },
];
