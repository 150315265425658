import Box from "@mui/material/Box";
import { FC } from "react";
import ListingCard, { ListingCardProps } from "../ListingCard/ListingCard";

interface Props {
  cards: ListingCardProps[];
  boxShadow?: number;
  sessionId: string;
  search: JSON
}

const CardList: FC<Props> = ({ cards, boxShadow = 4, sessionId, search, isMobile = false }) => {
  return (
    <Box>
      {cards.map(card =>(
        <div key={card.id}>
          <Box
            sx={{ maxWidth: "100%", backgroundColor: "white", py: '0.8rem' }}
          >
            <ListingCard isMobile={isMobile} {...card} sessionId={sessionId} hotel={card} id={card.id} page="listings" search={search} />
          </Box>
        </div>
      ))}
    </Box>
  );
};

export default CardList;
