import { useState, useEffect } from "react";

const RECENT_SEARCHES_KEY = "recentSearches";

 
export interface RecentSearch {
  address: string;
}

export function useRecentSearches() {
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);

  useEffect(() => {
    const stored = localStorage.getItem(RECENT_SEARCHES_KEY);
    if (stored) {
      setRecentSearches(JSON.parse(stored));
    }
  }, []);

  const addRecentSearch = (search: RecentSearch) => {
    console.log("search", search)
    setRecentSearches(prev => {
 
      const filtered = prev.filter(item => item.address !== search.address);
      const updated = [search, ...filtered].slice(0, 5);
      localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updated));
      return updated;
    });
  };

  const clearRecentSearches = () => {
    localStorage.removeItem(RECENT_SEARCHES_KEY);
    setRecentSearches([]);
  };

  return { recentSearches, addRecentSearch, clearRecentSearches };
}
