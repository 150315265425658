import { useEffect, useState, Fragment } from "react";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slider.css";
import { Carousel } from "react-responsive-carousel";

interface Props {
  images: string[];
  name: string;
  indicators?: boolean;
  sx?: any;
  forceLarge?: boolean;
  page?: string;
  height?: string;
  imageCount?: number;
}

const ImageSlider: FC<Props> = ({ sx, images, height = '301px', noBorderRadius = false, name, forceLarge, imageCount = 10, page, small = false, isMobile }) => {
  const [items, setItems] = useState<JSX.Element[]>([]);
  const [item, setItem] = useState(0);

  let borderRadius = noBorderRadius ? '0' : '6px 0px 0px 6px'

  if (page === 'listings') {
    borderRadius = noBorderRadius ? '0' : '6px 0px 0px 6px';
  } else if (page === 'detail-room') {
    borderRadius = noBorderRadius ? '0' : '6px 0px 0px 6px';
  }

  useEffect(() => {
    if (images && images.length > 0) {
      const components =
        forceLarge ?
          images.slice(0, 3).map((img, i) => <img key={img + i} style={{
            display: 'block',
            width: '100%',
            height: isMobile ? '180px' : small ? height : '280px',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: noBorderRadius ? '0' : '6px 6px 0px 0px',
          }} src={img.replace(/'/g, "%27")} alt="hotel image" loading="lazy" />)
          : images.slice(0, imageCount).map((img, i) => (
            <Fragment key={img + i + name}>
              <Box
                sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
              >
                <img
                  key={img + i}
                  style={{
                    display: 'block',
                    width: '100%',
                    height: height,
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderRadius: borderRadius,
                    ...sx,
                  }}
                  src={img.replace(/'/g, "%27")}
                  loading="lazy"
                  alt="hotel image"
                />
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                <img
                  key={img + i + name}
                  style={{
                    display: 'block',
                    width: '100%',
                    height: '244px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderRadius: noBorderRadius ? '0' : '6px 6px 0 0',
                    ...sx,
                  }}
                  src={img.replace(/'/g, "%27")}
                  loading="lazy"
                  alt="hotel image"
                />
              </Box>
            </Fragment>))
      setItems(components);
    }
  }, [images]);

  return (
    (items.length > 0 && (
      <Carousel
        infiniteLoop
        selectedItem={item || 0}
        showStatus={false}
        showIndicators={false}
        showArrows={true}
        onChange={(i) => {
          setItem(i)
        }}
        showThumbs={false}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={100}
        dynamicHeight
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                position: "absolute",
                zIndex: 2,
                top: "50%",
                left: 15,
                transform: "translateY(-50%)",
                width: 30,
                height: 30,
                border: "none",
                borderRadius: "50%",               
                backgroundColor: "rgba(0,0,0,0.3)",  
                cursor: "pointer",
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "background 0.2s ease"  
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(0,0,0,0.6)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(0,0,0,0.3)";
              }}
            >
      
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderTop: "6px solid transparent",
                  borderBottom: "6px solid transparent",
                  borderRight: "6px solid #fff",    
                }}
              />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                position: "absolute",
                zIndex: 2,
                top: "50%",
                right: 15,
                transform: "translateY(-50%)",
                width: 30,
                height: 30,
                border: "none",
                borderRadius: "50%",            
                backgroundColor: "rgba(0,0,0,0.3)",
                cursor: "pointer",
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "background 0.2s ease"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(0,0,0,0.6)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(0,0,0,0.3)";
              }}
            >
      
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderTop: "6px solid transparent",
                  borderBottom: "6px solid transparent",
                  borderLeft: "6px solid #fff",   
                }}
              />
            </button>
          )
        }

      >
        {items}
      </Carousel>
    )) || <Grid></Grid>
  );
};

export default ImageSlider;
