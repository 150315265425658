/*global google*/
import { FC, useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  MarkerF,
  MarkerClustererF,
  useJsApiLoader,
} from "@react-google-maps/api";

import useWindowSize from "../../../hooks/UseWindowSize";
import stylesArray from "./GoogleMapStyles";
import Skeleton from "@mui/material/Skeleton";
import ListingCard from "../../ListingCard";

interface Props {
  center: { lat: number; lng: number };
  height?: string | number | undefined;
  width?: string | number | undefined;
  markers?: {
    lat: number;
    lng: number;
    type: string;
    label?: string;
  }[];
  selectedMarker?: number;
  zoom?: number;
  clickable?: boolean;
  isFullScreen?: boolean;
  disabled?: boolean;
}

const libraries = ['places'];

const initOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  zoomControlOptions: { position: 7 },
  keyboardShortcuts: false,
  styles: stylesArray,
}

const disabledOptions = {
  gestureHandling: 'none',
  disableDefaultUI: true
}

const CustomInfoWindow: FC<{ showInfoContents: any, onClose: () => void }> = ({ showInfoContents, onClose }) => {
  return (
    <div style={{
      position: 'absolute',
      bottom: 10,
      left: '50%',
      transform: 'translateX(-50%)',
      maxWidth: '800px',
      width: '100%',
      // backgroundColor: 'white',
      // boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.3)',
      zIndex: 1000,
      // padding: '10px',
    }}>
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          background: 'transparent',
          border: 'none',
          fontSize: '20px',
          color: '#03989E',
          cursor: 'pointer',
          display: 'flex',
          fontWeight: 'semibold',
          alignItems: 'center',
          zIndex: 1001
        }}
      >
        ✖
      </button>
      <ListingCard {...showInfoContents} hotel={showInfoContents} variant="map" />
    </div>
  );
};

const Map: FC<Props> = ({
  center,
  height,
  width,
  markers,
  zoom = 10,
  clickable = true,
  isFullScreen = false,
  disabled = false,
}) => {
  const [containerStyle, setContainerStyle] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [mapOptions, setMapOptions] = useState(initOptions);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries
  });

  const size = useWindowSize();

  useEffect(() => {
    setContainerStyle({
      width: Number(width) || size.width,
      height: Number(height) || size.height,
    });

    let newOptions = disabled
      ? { ...mapOptions, ...disabledOptions }
      : mapOptions
    if (size.width > 720 && !disabled) {
      newOptions = { ...newOptions, zoomControl: true }
    } else {
      newOptions = { ...newOptions, zoomControl: false }
    }

    setMapOptions(newOptions);
  }, [size]);

  const [showInfo, setShowInfo] = useState(false);
  const [showInfoPosition, setShowInfoPostion] = useState({
    lat: 0,
    lng: 0,
  });

  const [showInfoContents, setShowInfoContents] = useState(null);

  const [clusterSize, setClusterSize] = useState(80);


  const mapRef = useRef<google.maps.Map | null>(null);

  const handleZoomChanged = () => {
    console.log(mapRef.current?.getZoom());
    if (mapRef.current?.getZoom() > 11) {
      // make this 0 to completely disable clustering when zoomed in
      setClusterSize(0);
    }
    else {
      setClusterSize(80);
    }
  };

  const getAnchorPoint = (text: string) => {
    const textLength = text.length;
    const offset = textLength * 3;  
    return new google.maps.Point(0, 10 + offset);
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={disabled
        ? {
          ...mapOptions,
          ...disabledOptions,
        } : mapOptions
      }
      zoom={size.width > 720 ? zoom : zoom - 1}
      onLoad={(map) => (mapRef.current = map)}
      onZoomChanged={handleZoomChanged}
    >
      {markers !== undefined && !disabled &&
        <MarkerClustererF
          calculator={(markers) => ({
            text: `${markers.length} properties`,
            index: 1, 
          })}
          gridSize={clusterSize}
          options={{
            averageCenter: true,
            styles: [
              {
                url:
                  "data:image/svg+xml;charset=UTF-8," +
                  encodeURIComponent(`
                 <svg xmlns="http://www.w3.org/2000/svg" width="100" height="25">
                   <path
                     fill="#03989E"
                     stroke="#ffffff"
                     stroke-width="1"
                     d="
                       M 10,0
                       H 90
                       A 10,10 0 0 1 100,10
                       V 15
                       A 10,10 0 0 1 90,25
                       H 10
                       A 10,10 0 0 1 0,15
                       V 10
                       A 10,10 0 0 1 10,0
                       Z
                     "
                   />
                 </svg>
               `),
                width: 100,
                height: 25,
                fontWeight: "400",
                textColor: "white",
                textSize: 13.5,
                anchor: [50, 13],
              },
            ],
          }}
        >
          {(clusterer) => (
            <>
              {markers.map((marker, index) => (
                <MarkerF
                  key={index}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  clusterer={clusterer}
                  label={{
                    text: `$${Math.abs(marker.lowestAveragePrice).toFixed(0)}`,
                    color: "white",
                    fontSize: "14px",  
                    fontWeight: "400",  
                  }}
                  icon={{
                   
                    path: `
                 M -8,-8
                 H 8
                 A 8,8 0 0 1 16,0
                 V 0
                 A 8,8 0 0 1 8,8
                 H -8
                 A 8,8 0 0 1 -16,0
                 V 0
                 A 8,8 0 0 1 -8,-8
                 Z
               `,
                    fillColor: "#03989E",
                    fillOpacity: 1,
                    strokeColor: "#ffffff",
                    strokeWeight: 1,
                    scale: 1.6,
                    anchor: getAnchorPoint(`$${Math.abs(marker.lowestAveragePrice).toFixed(0)}`),
                  }}
                  onClick={() => {
                    if (!clickable) return;
                    if (marker.label) {
                      setShowInfoPostion({ lat: marker.lat, lng: marker.lng });
                      setShowInfoContents(marker.hotel);
                      setShowInfo(true);
                    }
                  }}
                />


              ))}
            </>
          )}
        </MarkerClustererF>

      }
      {showInfo && (
        <CustomInfoWindow
          showInfoContents={showInfoContents}
          onClose={() => setShowInfo(false)}
        />
      )}
    </GoogleMap>
  ) : (
    <Skeleton
      variant="rectangular"
      animation="wave"
      height="100%"
      width="100%"
    />
  );
};

export default Map;
