import { FC, useState, MouseEventHandler, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import {
  IconButton,
  Button,
  Box,
  Typography,
  Grid,
  CSSObject,
  Dialog,
} from "@mui/material";
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import SearchIcon from "@mui/icons-material/Search";
import { Close, Event } from "@mui/icons-material";
import { saveSearch } from "../../store/searchReducer";
import { DateTime } from "luxon";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRange } from '@mui/x-date-pickers-pro';
import '../../mobileCalendar.css';

import OccupantSelector, { Occupant } from './OccupantSelector/OccupantSelector';
import LocationSelector from './LocationSelector';

interface FilterBarProps {
  sx?: CSSObject;
  home?: boolean;
  city?: string;
  onSearch?: any;
  flag?: string;
  bookingId?: string;
  mapOnPage?: boolean;
}

const FilterBar: FC<FilterBarProps> = ({
  sx,
  home = true,
  city = "",
  onSearch,
  flag,
  bookingId,
  mapOnPage = false,
}) => {

  const calendarRef = useRef(null)
  const mobileTextSearchRef = useRef(null)

  const search = useSelector((state: any) => state.searchReducer.search);
  const [selectedCity, setSelectedCity] = useState(city ? city : search.city ? {
    city: search.city,
    lat: search.lat,
    lng: search.lng
  } : null);

  const [showCities, setShowCities] = useState(false);

  const [formError, setFormError] = useState("");

  const [checkDate, setCheckDate] = useState<Array<Date | null>>([
    DateTime.local().plus({ days: 1 }).toJSDate(),
    DateTime.local().plus({ days: 3 }).toJSDate(),
  ]);

  const [newValue, setNewValue] = useState(search.city ? {
    city: search.city,
    lat: search.lat,
    lng: search.lng
  } : null);
  const [mobileText, setMobileText] = useState(search?.city?.description || '')

  const [occupants, setOccupants] = useState(
    search.occupants.dogs > 0
      ? search.occupants
      : { adults: 2, children: 0, dogs: 1 }
  );
  const navigate = useNavigate();

  const dispatch: Dispatch<any> = useDispatch();

  const onOccupantChange = (value: Occupant) => setOccupants(value);

  useEffect(() => {
    if (showCities) {
      setTimeout(() => {
        mobileTextSearchRef.current.focus()
      }, 100)
    }
  }, [showCities])

  const handleDateRangeClose = () => {
    setIsAccept(false);
    setOpen(false);
  };

  const handleFilterOutClick: MouseEventHandler<Element> = () => {
    // TagManager.dataLayer({ dataLayer: { event: "clicked_search" } });
    if (
      occupants.adults !== 0 &&
      selectedCity &&
      checkDate[0] &&
      checkDate[1]
    ) {

      if (onSearch) {
        onSearch(selectedCity, checkDate[0], checkDate[1], occupants)
        return
      }
      const checkIn = new Date(checkDate[0]).toISOString()
      const checkOut = new Date(checkDate[1]).toISOString()
      setFormError("");
      dispatch(
        saveSearch({
          city: newValue.city,
          checkIn,
          checkOut,
          occupants,
          lat: newValue.lat,
          lng: newValue.lng,
        })
      );
      navigate(`/listings?city=${newValue?.city?.description}&checkIn=${checkIn}&checkout=${checkOut}`, { state: { flag, bookingId } });

      // navigate("/listings", { state: { flag, bookingId } });
    } else {
      alert("error");
      if (!selectedCity) {
        setFormError("Location required");
      }
      if (!checkDate[0]) {
        setFormError("Check-in date required");
      }
      if (
        checkDate[0] &&
        new Date(checkDate[0]) <= new Date(new Date().setHours(23, 59, 59, 0))
      ) {
        setFormError("Check-in date must be today at the earliest");
      }
      if (!checkDate[1]) {
        setFormError("Check-out date required");
      }
      if (
        checkDate[1] &&
        new Date(checkDate[1]) <= new Date(new Date().setHours(23, 59, 59, 0))
      ) {
        setFormError("Check-out date must be after today");
      }
      if (occupants.adults === 0) {
        setFormError("Search must include at least 1 adult guest");
      }
    }
  };

  const [showMobileCalendar, setShowMobileCalendar] = useState(false)
  const [predictions, setPredictions] = useState([])

  const handlePredictions = (newPredictions) => {
    if (!newPredictions.some(prediction => prediction.description === search.city.description)) {
      setPredictions(newPredictions)
      setShowCities(true)
    }
  }

  const handleCityClick = (e, city) => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ 'address': city.description }, function (results, status) {

      if (status == google.maps.GeocoderStatus.OK) {
        dispatch(
          saveSearch({
            city: city,
            checkIn: new Date(checkDate[0]).toISOString(),
            checkOut: new Date(checkDate[1]).toISOString(),
            occupants,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          })
        );

        setSelectedCity({
          city: city,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        })
        setNewValue({
          city: city,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        })
        setShowCities(false)
        setMobileText(city?.description)

      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  const inputContainerStyle = {
    display: "flex",
    width: '95%',
    transition: "all .15s ease-in-out",
    alignItems: "center",
    maxHeight: "45px",
    mt: "0.70rem",
  }



  return (
    <>
      <Box
        sx={{
          zIndex: 2,
          width: (home ? '90%' : '100%'),
          ml: (home ? '1.5rem' : 0),
          mt: '1rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: (home ? "0rem" : '0.5em'),
            mt: ".5rem",
            pb: '0.75rem',
            pt: '0rem',
            backgroundColor: (home ? 'rgba(235, 236, 236, 0.7)' : 'transparent'),
            borderRadius: '5px'
          }}
        >
          <Box sx={inputContainerStyle}>
            <LocationSelector
              mobileText={mobileText}
              setMobileText={setMobileText}
              setShowCities={setShowCities}
              handlePredictions={handlePredictions}
              handleCityClick={handleCityClick}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              setNewValue={setNewValue}
              newValue={newValue}
              mobileTextSearchRef={mobileTextSearchRef}
              showCities={showCities}
              predictions={predictions}
              setPredictions={setPredictions}
              mapOnPage={mapOnPage}
            />
          </Box>

          <Box sx={inputContainerStyle}>
            <Box sx={{ width: '100%' }}>
              {/* Full-Screen Dialog */}
              <Dialog fullScreen open={showMobileCalendar} onClose={() => setShowMobileCalendar(false)}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "100%" }}>
                    {/* Close Button */}
                    <IconButton
                      onClick={() => setShowMobileCalendar(false)}
                      sx={{ alignSelf: "flex-end", position: 'absolute', top: 35, right: 35 }}
                    >
                      <Close />
                    </IconButton>
                    {/* Title */}
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        color: "#009688",
                        height: "fit-content",
                      }}
                      mt={5} fontWeight="semibold" align="center" gutterBottom
                    >
                      Select Dates
                    </Typography>

                    <DateRangeCalendar
                      calendars={1}
                      format="MMM dd"
                      sx={{
                        // Scale up the entire calendar
                        transform: 'scale(1.2)',
                        transformOrigin: 'top center',
                      }}
                      minDate={new Date()}
                      onChange={(newValue: DateRange<Date>) => {
                        setFormError("");
                        setCheckDate(newValue);

                      }}
                      value={checkDate}


                    />
                    {/* Action Buttons */}
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 8, width: "100%", maxWidth: "320px" }}>
                      <Button
                        variant="outlined"
                        onClick={() => setCheckDate([null, null])}
                        sx={{ flex: 1, marginRight: 1, borderRadius: "8px", fontSize: "1rem", textTransform: "none" }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setShowMobileCalendar(false)}
                        sx={{ flex: 1, borderRadius: "8px", fontSize: "1rem", textTransform: "none" }}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Box>
                </LocalizationProvider>
              </Dialog>


              <Grid
                onClick={() => setShowMobileCalendar(true)}
                sx={{
                  border: "1px solid #aaabab",
                  borderRadius: "6px",
                  padding: ".25rem .25rem .25rem 1rem",
                  backgroundColor: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  height: '45px',
                  alignItems: 'center',
                  width: '100%',
                  margin: 'auto',
                }}
              >
                <Grid
                  item
                  sx={{
                    pl: '0.1em',
                    pr: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Event sx={{ height: "24px" }} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="base">
                    {checkDate[0]
                      ? DateTime.fromJSDate(
                        new Date(checkDate[0])
                      ).toFormat("MMM dd")
                      : "Check-in date"}
                    &nbsp;&#8212;&nbsp;
                    {checkDate[1]
                      ? DateTime.fromJSDate(
                        new Date(checkDate[1])
                      ).toFormat("MMM dd")
                      : "Check-out date"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={inputContainerStyle}>
            <OccupantSelector
              value={occupants}
              onChange={onOccupantChange}
              variant="standard"
              size="small"
            />
          </Box>

          <Button
            fullWidth
            onClick={handleFilterOutClick}
            disableElevation
            type="submit"
            variant="contained"
            sx={{
              height: "40px",
              width: "94%",
              display: "flex",
              alignItems: "center",
              padding: ".25rem 0rem",
              justifyContent: "center",
              mt: "1rem",
              borderRadius: "20px",
              textTransform: "none",
              pointerEvents: "auto",
              fontSize: '18px'
            }}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FilterBar;