import { FC } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface NumberInputProps {
  value: number;
  onChange: (value: number) => void;
  minimum?: number;
  maximum?: number;
}

const NumberInput: FC<NumberInputProps> = ({
  value,
  onChange,
  minimum = 0,
  maximum = Infinity,
}) => {
  const handleIncrement = () => {
    if (value < maximum) {
      onChange(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > minimum) {
      onChange(value - 1);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={handleDecrement}
        disabled={value <= minimum}
        sx={{
          color: value <= minimum ? "grey.400" : "inherit",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: value <= minimum ? "grey.400" : "inherit",
          width: 30,
          height: 30,
        }}
      >
        <RemoveIcon fontSize="small" />
      </IconButton>
      <Typography sx={{ mx: 2 }}>{value}</Typography>
      <IconButton
        onClick={handleIncrement}
        disabled={value >= maximum}
        sx={{
          color: value >= maximum ? "grey.400" : "inherit",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: value >= maximum ? "grey.400" : "inherit",
          width: 30,
          height: 30,
        }}
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default NumberInput;